import {
  SCREEN_DIMENSIONS_SAVE
} from "../actions/types";

export default function(state = {}, action) {
  switch (action.type) {
    case SCREEN_DIMENSIONS_SAVE:
      return { 
        ...state,
        ...action.payload
      };
    default:
      return state 
  }
}